<template>

    <div>
        <el-dialog title="组织架构" :visible.sync="dialogShow" width="1000px" top="2%" :close-on-click-modal="false"
            @close="closeDialog">

<!--            <div style="position:absolute;top:30px;right:83px">
                <el-button size="small" type="success" @click="saveUsers" icon="el-icon-check">确认选择</el-button>
            </div>-->
            <el-row :gutter="20">
                <el-col :span="notcls?24:12" v-if="!notdept">
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            教师组织架构

                            <div style="float:right">
                                <el-button size="small" :type="ckdAllTeachers?'primary':''" @click="checkAllTeachers"
                                    icon="el-icon-circle-check">{{ckdAllTeachers?'已选':'选择'}}全部教师</el-button>
                            </div>

                        </div>
                        <div class="treeContainer" style="height:70vh;overflow:auto">

                            <tnode v-for="(item,idx) in treeData" :key="idx" :tdata="item" :oteachers="ckdTeachers">
                                <div style="height:100px"></div>
                            </tnode>


                        </div>
                    </el-card>

                </el-col>
                <el-col :span="notdept?24:12" v-if="!notcls">
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            班级组织架构
                            <div style="float:right">
                                <el-button size="small" :type="ckdAllClasses?'primary':''" @click="checkAllClasses"
                                    icon="el-icon-circle-check">{{ckdAllClasses?'已选':'选择'}}全部班级</el-button>
                            </div>
                        </div>
                        <div class="treeContainer" style="height:70vh;overflow:auto">

                            <cnode v-for="(item,idx) in clsData" :key="idx" :tdata="item" :odic="ckdClasses"></cnode>
                            <div style="height:100px"></div>
                        </div>
                    </el-card>

                </el-col>
            </el-row>
          <div slot="footer">
            <el-button size="small" type="primary" @click="saveUsers" icon="el-icon-check">确认选择</el-button>
          </div>
        </el-dialog>
    </div>

</template>


<script>

import tnode from "./tnode.vue"
import cnode from "./cnode.vue"
export default {
    components: {
        tnode, cnode
    },
    props: ["notcls", "notdept", "oteachers", "oclasses","bjxz"],//true,不显示班级，不显示部门
    data() {
        return {
            dialogShow: true,
            treeData: [],
            ckdTeachers: {},
            clsData: [],
            ckdClasses: {},
            ckdAllTeachers: false,
            ckdAllClasses: false,
        }
    },
    mounted() {
        console.log(this.oteachers)
        if (this.oteachers) {
            this.oteachers.split(",").map(e => { if (e > 0) { this.ckdTeachers[e] = true } })
        }
        if (this.oclasses) {
            this.oclasses.split(",").map(e => { if (e > 0) { this.ckdClasses[e] = true } })
        }
        this.$http.post("/api/get_teacher_info").then(res=>{
            if(res.data.sys_roles){
                let isAdmin = false;
                for(let item of res.data.sys_roles){
                    if(item.role_name=='系统管理员'||item.role_name=='校领导'||item.role_name=='年级组长'){
                        
                        isAdmin = true
                        break
                    }
                }
                if(!isAdmin){
                    this.notdept = true
                }
                this.getOfficeTree()
            }
        })
       
    },
    methods: {
        closeDialog() {
            this.dialogShow = false;
            this.$$parent(this, "dialogVisible", false)
        },
        getOfficeTree() {
            if (!this.notdept) {
                this.$http.post("/api/office_tree_teachers").then(res => {
                    let treeData = res.data
                    let diguiCk = e => {
                        if (e.teachers && e.teachers.length > 0) {
                            e.teachers.map(item => {
                                if (this.ckdTeachers[item.id]) {
                                    item.ckd = true
                                } else {
                                    item.ckd = false
                                }

                            })
                        }
                        if (e.children && e.children.length > 0) {
                            for (let c of e.children) {
                                diguiCk(c)
                            }
                        }
                    }
                    for (let c of treeData) {
                        diguiCk(c)
                    }

                    this.treeData = treeData

                })
            }
            if (!this.notcls) {
                //获取自己权限下的班级get_tch_cls_data
                //组织架构的班级用office_tree_classes


                this.$http.post("/api/get_tch_cls_data", {
                    bjxz:this.bjxz
                }).then(res => {
                    let clsData = res.data.bj_list
                    let grade={}
                    for (let item of clsData) {
                        if (this.ckdClasses[item.id]) {
                            item.ckd = true
                        } else {
                            item.ckd = false
                        }
                        if(!grade[item.upname]){
                            grade[item.upname]={name:item.upname,children:[item]}
                        }else{
                            grade[item.upname].children.push(item)
                        }
                        
                    }
                    let arr=[]
                    for(let k in grade){
                        arr.push(grade[k])
                    }
                    this.clsData = arr
                   
                    return
                    let diguiCk = e => {
                        if (e.children && e.children.length > 0) {

                            if (e.children[0].type == 'bj') {
                                e.children.map(item => {
                                    if (this.ckdClasses[item.id]) {
                                        item.ckd = true
                                    } else {
                                        item.ckd = false
                                    }

                                })
                            } else {
                                for (let c of e.children) {
                                    diguiCk(c)
                                }
                            }
                        }

                    }
                    for (let c of clsData) {
                        diguiCk(c)
                    }
                    this.clsData = clsData
                })
            }
        },
        setCkdTeachers(e) {
            this.ckdTeachers[e.id] = e.ckd
        },
        setCkdClasses(e) {
            this.ckdClasses[e.id] = e.ckd

        },
        checkAllTeachers() {
            this.ckdAllTeachers = !this.ckdAllTeachers


            let treeData = JSON.parse(JSON.stringify(this.treeData))
            this.treeData = []
            let diguiCk = e => {
                if (e.teachers && e.teachers.length > 0) {
                    e.teachers.map(item => {
                        item.ckd = this.ckdAllTeachers;
                        this.ckdTeachers[item.id] = item.ckd
                    })
                }
                if (e.children && e.children.length > 0) {
                    for (let c of e.children) {
                        diguiCk(c)
                    }
                }
            }
            for (let c of treeData) {
                diguiCk(c)
            }
            setTimeout(() => {
                this.treeData = treeData
            }, 0)
        },
        checkAllClasses() {
            this.ckdAllClasses = !this.ckdAllClasses
            this.$$parent(this, "allStudent", this.ckdAllClasses)

            let clsData = JSON.parse(JSON.stringify(this.clsData))
            this.clsData = []
            let diguiCk = e => {
                if (e.children && e.children.length > 0) {

                    if (e.children[0].type == 'bj') {
                        e.children.map(item => {
                            item.ckd = this.ckdAllClasses;
                            this.ckdClasses[item.id] = item.ckd

                        })
                    } else {
                        for (let c of e.children) {
                            diguiCk(c)
                        }
                    }
                }

            }
            for (let c of clsData) {
                diguiCk(c)
            }
            setTimeout(() => {
                this.clsData = clsData
            }, 0)
        },
        saveUsers() {
            let teachers = [], cls = [];
            for (let k in this.ckdTeachers) {
                if (this.ckdTeachers[k]) {
                    teachers.push(k)
                }
            }

            for (let k in this.ckdClasses) {
                if (this.ckdClasses[k]) {
                    cls.push(k)
                }
            }

            //过滤掉班级性质范围外的班级
            // for (let c of this.clsData) {
            //     if (c.children) {
            //         for (let bj of c.children) {
            //             for (let k in this.ckdClasses) {
            //                 if ((bj.id == k) && this.ckdClasses[k]) {
            //                     cls.push(k)
            //                 }
            //             }
            //         }
            //     }
            // }

            this.$$parent(this, "setTarget", { teachers: teachers, classes: cls })
            this.closeDialog()

        }

    }
}

</script>