let bb = `18752006163
18252047330
15151880596
18305191256
18851691586
18752006525
18305192678
18851691262
15161761501
13327815234
18305193577
17761712201
15151889700
18052006676
13376060698
15152631202
13222047392
13951601098
15996203118
18351584114
15951700946
15151883872
19822699556
15151822501
18583163876
19951718872
18305192668
13047552530
15062265873
18252063627
18252012891
18305193228
18752006516
15850506014
18652981880
13505196993
18305193302
18321162513
15951805009
18851956750
17749541226
13951813994
15951800639
18752006602
18913914371
18912985010
19726162977
17714314669
13805169381
13912982276
13245867326
19951933958
18951702355
18951680881
15251710682
18112930162
13951007980
13912958748
18512522562
18761672026
15205159752
13913015321
18851712672
13913015211
15805177146
18021396546
13851989916
18362906463
13042524290
19951664482
13376063730
13815867974
18951425046
17766082790
13815421367
17314454477
15850647450
15365073089
15365073089
13814060790
13952034159
15005142470
18651686875
17302546814
18724017769
13851648148
18852031982
19502260991
13805248069
13813085849
19941501911
13218054085
17505174663
15601826351
13913845505
18798938065
18260007683
17714525707
19551958589
13451941510
15905169216
18013851906
13913377598
13851616916
15952007564
15895924513
18061786075
13770830338
15951943646
13401997753
18344668424
18851005597
18951624815
19844554315
15077877508
19848346860
13357832767
18115460335
15005181547
15312035712
15950453045
13655185078
19951956935
13851939462
18860847256
15895972458
18851067734
15261870398
15380817065
13813040298
18305164678
17366032867
13951797090
18751937696
17366032867
13951797090
18751937696
18362931120
13951030246
15295752913
15996230087
13222028625
16601791672
15850635941
13770961322
18805151405
18913044943
17384476082
17384476082
13814522833
17826001386
13914490499
18852753988
15751851039
18351990390
15261481283
13814199884
13338606919
13914783310
15850637141
18014498605
13337865612
17351759880
13372026667
19505162252
18752066623
15195789025
19895898856
18795836385
13115007892
19951795939
18051083558
13851669537
19505172353
15250977197
17026081789
18019960692
18351901005
18651867910
19505173307
13913315227
19850822836
13952074098
13022533713
13805146924
19356720771
13270832926
17639245083
19984883932
13952079518
15195823970
19509923688
15995448220
17715293524
17130496667
15705188723
15651803807
13276647553
17751759009
18151683907
13952300688
15161761501
13814076425
13601458476
15251882997
17612544475
18014466856
18912902799
17396806376
13770870449
18118851920
13327711515
19816406681
15996357245
18351901811
18761832365
18252012891
13524615840
19851895581
13851594337
18013397508
13912910567
13913310290
18551728702
18994011665
13022594626
13276669091
13913374448
15852943220
13770830338
17372250938
19825094227
18694975068
13770856270
17826057383
19822699556
19555276125
17361869519
17751756302
14751633348
15366191482
13565157209
13851442421
15365066186`


let getPhones = () => {
    console.log(1212)
    console.log(bb)
    let arr_b = bb.split('\n')
    let result_arr = []
    console.log(1215, arr_b)
    for (let b of arr_b) {
        b = b.replace(/\s/g, "");
        result_arr.push(`"${b}"`)
    }
    console.log(734, result_arr.join(','))
    return result_arr.join(',')
}
let arr = getPhones()
export default arr