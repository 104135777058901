<template>
	<div>
		<el-dialog title="发送短信" :visible.sync="showDialog" width="90%" :close-on-click-modal="false"
			custom-class="width_800 cus_dialog" @close="closeDialog">
			<div slot="title" class="flexBetween">
				<span style="color: #ffffff;">发送短信</span>
				<div class="flexEnd">
					<el-button size="small" icon="el-icon-bell" @click="reNotify(1)"
						type="warning">给未参与学生发短信</el-button>

					<el-button size="small" icon="el-icon-download" @click="downXls"
						type="success">下载未参与学生名单</el-button>
					<!-- <el-button size="small" icon="el-icon-download" @click="exportNoAnswers"
						type="success">下载未参与学生名单</el-button>
					 -->

					<el-button v-if="false" size="small" icon="el-icon-download" @click="downXls_resend"
						type="primary">下载需重新发送短信名单</el-button>
				</div>
			</div>

			<div class="h_100 flexCloumn">
				<el-row style=" margin-bottom: 10px">
					<el-input placeholder="请输入关键字" v-model="nformData.keyword" class="input-with-select"
						style="width: 280px;" size="small">
						<el-select v-model="nformData.ktype" slot="prepend" placeholder="请选择" style="width: 120px;">

							<el-option label="学生姓名" value="stu_name"></el-option>
						</el-select>
					</el-input>

					<el-cascader class="ml10" size="small" ref="classTree" :options="ClassList"
						:props="{ value: 'id', label: 'title', checkStrictly: true, emitPath: false }" placeholder="选择班级"
						@change="handleChangeClass" clearable style="width:400px;"></el-cascader>
					<el-select v-model="nformData.joined" placeholder="请选择" size="small"
						style="width: 150px; margin-left: 10px;">
						<el-option label="全部参与状态" value=""></el-option>
						<el-option label="未参与" :value="0"></el-option>
						<el-option label="已参与" :value="1"></el-option>

					</el-select>
					<el-button size="small" icon="el-icon-search" style="margin-left: 10px;" @click="noticeSearch" plain
						type="primary">查询</el-button>
					<el-button size="small" icon="el-icon-refresh" @click="noticeReset" type="warning"
						plain>重置</el-button>



				</el-row>
				<el-table class="flex_1" :data="noticedataList" size="small" stripe border style="width:100%;">
					<el-table-column type="index" label="序号" width="80"></el-table-column>
					<el-table-column prop="stu_name" label="姓名" min-width="80"></el-table-column>
					<el-table-column prop="schoolName" label="学校" min-width="200"></el-table-column>
					<el-table-column prop="jc_name" label="专业" min-width="150"></el-table-column>
					<el-table-column prop="class_name" label="班级" min-width="120"></el-table-column>
					<el-table-column prop="zy_xingzhi" label="班级性质" min-width="150"></el-table-column>
					<el-table-column prop="phone" label="手机号" min-width="125">
						<template slot-scope="scope">
							<span style="color:#1289ff;">{{ (scope.row.phone || scope.row.sch_phone) }}</span>

						</template>
					</el-table-column>
					<el-table-column prop="n" label="短信发送次数" min-width="100">
					</el-table-column>


					<el-table-column prop="email" label="是否参与" min-width="100">
						<template slot-scope="scope">
							<span v-if="scope.row.joined" style="color: green;" @click="viewInfo(scope.row)">已参与</span>
							<span v-else style="color: orangered;">未参与</span>

						</template>
					</el-table-column>

<el-table-column prop="" label="操作" min-width="100">
						<template slot-scope="scope">
							<el-tag class="pointer" v-if="(!scope.row.joined)" @click="sendSmsOne(scope.row)">发短信</el-tag>

						</template>
					</el-table-column>


				</el-table>

				<el-pagination @current-change="handlePageChange" @size-change="handleSizeChange"
					:current-page="page.current_page" :page-size="page.per_page" :total="page.count"
					layout="total, sizes,prev, pager, next" :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
				</el-pagination>
			</div>
		</el-dialog>


		<el-dialog title="作答详情" :visible.sync="showWenjuan" width="800px" custom-class="width_800 cus_dialog" top="5%">
			<h5result :id="joinedID" :ivstid="ivstId" v-if="showWenjuan"></h5result>
		</el-dialog>

	</div>
</template>

<script>
import h5result from "./h5result.vue";
import utilPhones from "./sendMsgPhones"
export default {
	props: ["plan"],
	components: { h5result },
	data() {
		return {
			showDialog: true,
			showDialog_notice: false,
			ClassList: [],
			nformData: {
				gyear: "",
				class_name: "",
				ktype: "stu_name",
				keyword: "",
				plan_id: 0,
				joined: ""
			},
			noticedataList: [],
			YearList: [],
			showWenjuan: false,
			joinedID: 0,
			ivstId: 0,
			page: {
				count: 0,
				current_page: 1,
				per_page: 20,
			},
		}
	},
	mounted() {
		console.log('this.plan')

		this.nformData.plan_id = this.plan.id
		this.getClass()
		this.noticeSearch()

	},
	methods: {

		showNotify(scope) {
			var self = this;
			self.showDialog_notice = true;
		},

		getClass() {
			this.$http.post("/api/get_plan_classes", {
				plan_id: this.plan.id
			}).then(res => {
				this.ClassList = res.data
			})
		},
		handlePageChange(page) {
			this.page.current_page = page
			this.noticeSearch()
		},
		handleSizeChange(val) {
			this.page.current_page = 1;
			this.page.per_page = val;
			this.noticeSearch()
		},

		noticeSearch() {

			this.$http.post("/api/by_ivst_sms_sendlog", {
				plan_id: this.plan.id,
				keyword: this.nformData.keyword,
				joined: this.nformData.joined,
				class_id: this.nformData.class_name,
				page: this.page.current_page,
				pagesize: this.page.per_page,
			}).then((res) => {

				this.noticedataList = res.data.data;
				this.page = res.data.page;

			});
		},
		noticeReset() {
			this.nformData = {};
			// this.getlist();
		},
		closeDialog() {
			this.$parent.showSms = false
		},
		reNotify(t) {
			this.$confirm('短信发送较慢，请勿多次点击发送按钮，否则将发送多条！', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http
					.post("/api/start_send_notify", {
						plan_id: this.plan.id,
						class_id: this.nformData.class_name,
					})
					.then((res) => {

						this.$message.success("后台正在发送中，切勿多次点击发送按钮！！！")

					});

			}).catch(() => {

			});

		},
		viewInfo(row) {
			this.joinedID = row.joined
			this.ivstId = row.ivst_id
			this.showWenjuan = true
		},
		sendSmsOne(row) {
			console.log(row)
			this.$http
				.post("/api/start_send_notify", { plan_id: this.plan.id, stu_id: row.stu_id })
				.then((res) => {

					this.$message.success("后台正在发送中，切勿多次点击发送按钮！！！")

				});
		},
		downXls() {
			this.$http
				.post("/api/by_download_notjoin", { plan_id: this.nformData.plan_id })
				.then((res) => {

					if (res.data.url) {
						window.open(res.data.url, "_blank")
					}

				});
		},
		exportNoAnswers() {
			this.$http.post('/api/export_no_answers', { plan_id: this.plan.id }).then(res => {
				if (res.data.url) {
					window.open(res.data.url, '_blank')
				}
			})
		},

		handleChangeClass(val) {
			if (val.toString().indexOf('sch') >= 0) {
				let nodes = this.$refs.classTree.getCheckedNodes()
				let ids = nodes[0].children.map(c => c.value).join(',')
				this.nformData.class_name = ids
			} else {
				this.nformData.class_name = val.toString()
			}
		},
		downXls_resend() {
			let phones = utilPhones
			this.$http
				.post("/api/by_download_resend", { phones: phones,aa:0 })
				.then((res) => {
					if (res.data.url) {
						window.open(res.data.url, "_blank")
					}

				});
		},
	
	},
}
</script>