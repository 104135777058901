<template>
	<div>
		<table class="dt" border="" cellspacing="" cellpadding="">
			<tr>
				<th>序号</th>
				<th>题目</th>
				<th>选项</th>
				<th>选择人数</th>
			</tr>
			<template v-for="(item,idx) in stuList">
				<tr v-if="item.tm_type.indexOf('radio')>=0||item.tm_type.indexOf('checkbox')>=0">
					<td style="width: 30px;" :rowspan="item.tm_content.length">{{idx+1}}</td>
					<td :rowspan="item.tm_content.length">{{item.tm_title}}</td>
					<td style="width: 300px;">
						{{item.tm_content&&item.tm_content.length>0?item.tm_content[0].title:''}}
					</td>
					<td style="width: 100px;">{{item.tm_content[0].choose_count}}</td>
				</tr>
				<tr v-else>
					<td style="width: 30px;" >{{idx+1}}</td>
					<td >{{item.tm_title}}</td>
					<td colspan="2">
						<span v-for="(ans ,i ) in item.answers" :key="'ans'+i">{{ans.answers[0]}};</span>
					</td>
					
				</tr>
				<template
					v-if="item.tm_type.indexOf('radio')>=0||item.tm_type.indexOf('checkbox')>=0&&item.tm_content&&item.tm_content.length>0">
					<tr v-for="i in item.tm_content.length-1">
						<td>{{item.tm_content[i].title}}</td>
						<td>{{item.tm_content[i].choose_count}}</td>
					</tr>
				</template>
				
				
				
				
				
				


			</template>


		</table>
	</div>
</template>

<script>
	export default {
		props: ["plan"],
		components: {

		},
		data() {
			return {
				class_name: "1412",
				stuList: []
			}
		},
		mounted() {
			console.log(this.plan)
			this.getList()
		},
		methods: {
			getList() {
				this.$http.post("/api/get_ivst_tj_tm", {
					plan_id: this.plan,
					class_name: this.class_name
				}).then(res => {
					this.stuList = res.data;

				})
			}
		},
	}
</script>

<style>
</style>
